import React from "react";
import Button from '@mui/material/Button';

const Title = () => {
    return (
        <div>
            <div align={'center'}>
                <img src={require('../images/title-cutted-edited.jpg')} alt={"title"} width={"50%"} align={"center"}/>
                <hr width="100%" size="2"></hr>
            </div>

            <div>
                <Button variant="contained" href="https://hv-4.ru">
                    Христианский вестник 4
                </Button>
                <hr width="100%" size="2"></hr>
            </div>
            <div>
                <Button variant="contained" href="https://hv-5.ru">
                    Христианский вестник 5
                </Button>
            </div>
        </div>
    );
};

export default Title;
